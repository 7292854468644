<template>
  <div class="readme-article">
    <h1 id="日常">五、日常</h1>
    <h2 id="通知公告">1.通知公告</h2>
    <p>1.1查看历史通告记录，显示通告信息标题，内容。</p>
    <p><img src="@/assets/img/call/5-1.png" /></p>
    <p>1.2发布员工通知，填写标题、内容即可提交。</p>
    <p><img src="@/assets/img/call/5-2.png" /></p>
    <h2 id="操作日志管理">2.操作日志管理</h2>
    <p>查看系统人员所有操作记录，显示操作人，操作时间，操作内容等信息。</p>
    <p><img src="@/assets/img/call/5-3.png" /></p>
    <h2 id="登陆日志管理">3.登陆日志管理</h2>
    <p>查看系统人员的登录记录，显示登录人，登录时间，操作系统等信息。</p>
    <p><img src="@/assets/img/call/5-4.png" /></p>
    <h2 id="电话解密日志">4.电话解密日志</h2>
    <p>查看电话解密日志，显示操作人，解密电话，时间等信息。</p>
    <p><img src="@/assets/img/call/5-5.png" /></p>
    <h2 id="日志清除">5.日志清除</h2>
    <p>一键清除日志，按日志保留天数清除超过设置天数的日志。</p>
    <p><img src="@/assets/img/call/5-6.png" /></p>
  </div>
</template>

<script>
export default {
  name: "call5-1",
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped>
</style>